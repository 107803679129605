import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import slide1 from '../../assets/hero/b4.avif';
import slide2 from '../../assets/hero/b5.avif';
import slide3 from '../../assets/hero/b6.avif';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import About from '../about/About';
import Services from '../services/Services';
import Gallery from '../gallery/Gallery';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <section className="relative">
      {/* Carousel Component with AutoPlay */}
      <Carousel 
        className="rounded-xl"
        autoPlay
        infiniteLoop
        interval={2000} 
        showArrows={false} 
        showStatus={false} // Optionally hide slide status
        showThumbs={false} // Optionally hide thumbnails
      >
        {/* First Slide */}
        <div className="relative h-[400px] w-full">
          <img
            src={slide1}
            alt="image 1"
            className="h-full w-full object-cover"
          />
          <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/75">
            <div className="w-3/4 text-center md:w-2/4">
              <h1 className="text-white mb-4 text-2xl md:text-4xl lg:text-5xl">
              Signs That Speak Louder
              </h1>
              <p className="text-white mb-12 opacity-80">
              Making your message unforgettable with creative designs.
              </p>
            
              <div className="flex justify-center gap-2">
                <Link to="/contact">
                <button className="bg-white text-black py-2 px-6 rounded-lg text-lg">
                 Contact Us
                </button>
                </Link>
                
                <Link to="/gallery">
                <button className="bg-transparent text-white py-2 px-6 rounded-lg text-lg border-2 border-white">
                  Gallery
                </button>
                </Link>
              
              </div>
            </div>
          </div>
        </div>

        {/* Second Slide */}
        <div className="relative h-[400px] w-full">
          <img
            src={slide2}
            alt="image 2"
            className="h-full w-full object-cover"
          />
          <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/75">
            <div className="w-3/4 text-center pl-12 md:w-2/4 md:pl-20 lg:pl-32">
              <h1 className="text-white mb-4 text-3xl md:text-4xl lg:text-5xl">
              Bold Signs, Bright Futures
              </h1>
              <p className="text-white mb-12 opacity-80">
              Delivering impactful designs that leave a lasting impression.
              </p>
              <div className="flex justify-center place-items-center gap-2">
                <Link to="/contact">
                <button className="bg-white text-black py-2 px-6 rounded-lg text-lg">
              Contact Us
                </button>
                </Link>
                <Link to="/gallery">
                <button className="bg-transparent text-white py-2 px-6 rounded-lg text-lg border-2 border-white">
                  Gallery
                </button>
                </Link>
               
              </div>
            </div>
          </div>
        </div>

        {/* Third Slide */}
        <div className="relative h-[400px] w-full">
          <img
            src={slide3}
            alt="image 3"
            className="h-full w-full object-cover"
          />
          <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/75">
            <div className="w-3/4 text-center pl-12 md:w-2/4 md:pl-20 md:pb-20 lg:pl-32 ">
              <h1 className="text-white mb-4 text-3xl md:text-4xl lg:text-5xl">
              Where Design Meets Visibility
              </h1>
              <p className="text-white mb-12 opacity-80">
              Crafting signboards that shine and elevate your brand.
              </p>
              <div className="flex justify-center gap-2">
                <Link to="/contact">
                <button className="bg-white  text-black py-2 px-6 rounded-lg text-lg">
              Contact Us
                </button>
                </Link>
            
                <button className="bg-transparent text-white py-2 px-6 rounded-lg text-lg border-2 border-white">
                  Gallery
                </button>
              </div>
            </div>
          </div>
        </div>
      </Carousel>
      <About/>
      <Services/>
      <Gallery/>
    </section>
  );
};

export default Home;
