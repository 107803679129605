import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Pages from './Components/pages/Pages';

function App() {
  return (
    <div className="App">
     <Pages/>
    </div>
  );
}

export default App;
