import React from "react";
import ACPImage from "../../assets/services/ACP.jpg";
import GlowImage from "../../assets/services/glow.jpeg";
import ThreeDImage from "../../assets/services/3d1.jpeg";
import Banners from "../../assets/services/banner.jpeg";
import flex from "../../assets/services/flex.jpg";
import logo2 from "../../assets/services/logo2.png";

const Services = () => {
  const services = [
    {
      id: 1,
      title: "Custom Signboards",
      description: "High-quality custom signboards to meet your branding needs.",
      image: ACPImage,
      link: "#",
    },
    {
      id: 2,
      title: "Glow Boards",
      description: "Bright and engaging LED displays for indoor and outdoor use.",
      image: GlowImage,
      link: "#",
    },
    {
      id: 3,
      title: "3D Image",
      description: "Signs with three-dimensional elements, often made from materials like acrylic, metal, or wood.",
      image: ThreeDImage,
      link: "#",
    },
    {
      id: 3,
      title: "Banners",
      description: "Large printed advertisements or announcements made of materials like vinyl or fabric.",
      image: Banners,
      link: "#",
    },
    {
      id: 3,
      title: "Flex",
      description: "A flexible, printable material used for large-format printing.Often used for outdoor banners,advertising displays.",
      image: flex,
      link: "#",
    },
    {
      id: 3,
      title: "Logo",
      description: " A graphical representation or symbol of a company, brand, or organization.",
      image: logo2,
      link: "#",
    },
  ];

  return (
    <div className="flex flex-col justify-center items-center p-6">
      {/* Heading and Subtitle */}
      <div className="text-center mb-8">
        <h2 className="text-4xl font-bold text-blue-600 mb-4">Our Services</h2>
        <h3 className="text-small text-gray-600">
          Discover the range of professional services we offer to elevate your brand.
        </h3>
      </div>

      {/* Cards Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-24">
        {services.map((service) => (
          <div key={service.id} className="card bg-white rounded-lg w-64 shadow-md mx-auto">
            <img
              src={service.image}
              className="card-img-top rounded-t-lg w-full h-40 object-cover"
              alt={service.title}
            />
            <div className="card-body p-4">
              <h5 className="card-title text-xl font-bold">{service.title}</h5>
              <p className="card-text text-gray-700">{service.description}</p>
              <a
                href={service.link}
                className="btn btn-primary mt-4 inline-block px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
              >
                Learn More
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
