import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from "../home/Home";

import About from "../about/About";
import Services from "../services/Services";
import Contact from "../contact/Contact";
import Navbar from '../../common/navbar/Navbar';
import Footer from '../../common/footer/Footer';

import AboutUs from '../about/AboutUs';
import TotalGallery from '../gallery/TotalGallery';

const Pages = () => {
  return (
    <Router>
      <Navbar/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs/>} />
        <Route path="/services" element={<Services />} />
        <Route path="/gallery" element={<TotalGallery/>} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/aboutus" element={<AboutUs/>}/>
      </Routes>
      <Footer/>
    </Router>
  );
};

export default Pages;
