import React from "react";
import { Link } from "react-router-dom";

const About = () => (
  <section className="p-8 bg-gradient-to-r text-black rounded-lg shadow-md flex items-start justify-center ">
    <div className="flex flex-col lg:flex-row items-start justify-center">
      <div className="lg:w-1/2">
        <h2 className="text-3xl font-bold text-center mb-4">
          About <span className="text-blue-500">PremDigitals</span>
        </h2>
        <h6 className="text-2xl font-bold text-center mb-8">
          Signboards - The art of making first impressions last forever.
        </h6>
        <div className="text-container">
          <p className="text-black text-justify ml-14 mt-4 lg:mt-0 lg:w-3/4">
            At PremDigitals, we pride ourselves on being a one-stop solution for all your signboard and branding needs.
            Our mission is to help businesses and individuals stand out with visually stunning and innovative designs 
            that not only grab attention but also leave a lasting impression.
          </p>
          <p className="text-black text-justify ml-14 mt-6 lg:w-3/4">
            We combine craftsmanship, technology, and creativity to deliver solutions that elevate your brand.
            Whether it’s for indoor displays, outdoor advertising, or special events, we are here to make your vision shine.
          </p>
        </div>
        <div className="mt-6 flex justify-center">
          <Link to="/aboutus">
            <button className="px-6 py-3 bg-blue-400 text-black font-semibold rounded-lg hover:bg-yellow-500">
              Learn More
            </button>
          </Link>
        </div>
      </div>
    </div>
  </section>
);

export default About;
