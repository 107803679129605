import React, { useState } from "react";
import { Link } from "react-router-dom";
import { nav } from "../../data/Data"; 

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  const contactInfo = [
    {
      icon: "fa-phone-alt",
      text: "9700277740",
    },
    {
      icon: "fa-envelope",
      text: "contactus@premdigitalsignage.com",
    },
    {
      icon: "fa-map-marker-alt",
      text: "Miyapur, HYD",
    },
  ];

  return (
    <nav className="bg-white sticky top-0 z-50 text-black shadow-md">
      {/* Top Section: Contact Information */}
      <div className="bg-blue-400 py-2 hidden md:block">
        <div className="container mx-auto px-4 flex justify-end items-center">
          <div className="flex space-x-6">
            {contactInfo.map((info, index) => (
              <div className="flex items-center space-x-2" key={index}>
                <i className={`fa-solid ${info.icon} text-white`}></i>
                <span className="text-sm text-white">{info.text}</span>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Main Navbar */}
      <div className="container mx-auto px-4 py-0 flex items-center justify-between">
        {/* Logo Section */}
        <div className="flex items-center space-x-3">
          <img src="logo3.png" alt="Logo" className="w-20 h-20" />
          <div className="text-4xl font-bold">
            Prem<span className="text-blue-500">Digital</span>
          </div>
        </div>

        {/* Desktop Navigation Links */}
        <ul
          className={`md:flex space-x-12 ${isMobileMenuOpen ? "hidden" : "block"} hidden md:block`}
        >
          {nav.map((item, index) => (
            <li key={index}>
              <Link
                to={item.path}
                className="text-black hover:text-yellow-400 capitalize"
              >
                {item.text}
              </Link>
            </li>
          ))}
        </ul>

        {/* Mobile Menu Button */}
        <button
          className="md:hidden text-2xl focus:outline-none"
          onClick={toggleMobileMenu}
        >
          <i className="fa-solid fa-bars"></i>
        </button>
      </div>

      {/* Off-canvas Mobile Menu */}
      {isMobileMenuOpen && (
        <div
          className="fixed inset-0 bg-gray-800 bg-opacity-50 md:hidden"
          onClick={toggleMobileMenu}
        >
          <div className="fixed top-0 right-0 w-64 bg-white h-full p-6">
            <button
              className="text-xl text-black mb-6"
              onClick={toggleMobileMenu}
            >
              <i className="fa-solid fa-times"></i> Close
            </button>
            <ul className="space-y-6">
              {nav.map((item, index) => (
                <li key={index}>
                  <Link
                    to={item.path}
                    className="text-black hover:text-yellow-400 capitalize"
                    onClick={toggleMobileMenu}
                  >
                    {item.text}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
